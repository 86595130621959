export enum Namespace {
    COMMON = 'common',
    PRODUCT_RATEPLAN_CHARGE = 'productrateplancharge',
}

/* if you add more languages to this enum, please make sure to also add the same value to the translation files.
   right now we have:
 "language": {
    "de": "Deutsch",
    "en": "Englisch"
  } 
  The language should then be automatically added to the language selector.
  Also add another folder under public/locales with the value as folder name
*/
export enum SupportedLanguage {
    EN = 'en',
    DE = 'de',
}
export default {
    // This is the list of languages your application supports
    supportedLngs: Object.values(SupportedLanguage),
    // This is the language you want to use in case
    // if the user language is not in the supportedLngs
    fallbackLng: SupportedLanguage.EN,
    // The default namespace of i18next is "translation", but you can customize it here
    defaultNS: Namespace.COMMON,
};
